import { useRoutes } from 'react-router-dom'

import { routes } from 'config'

const AppRouter = () => {
  const router = useRoutes(routes)

  return router
}

export default AppRouter
