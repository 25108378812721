import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectDiamond } from 'store/selectors'
import { getDiamondAction } from 'store/actions'
import { Searchbar, Diamond, NoResults, Disclaimer, Footer } from 'components'

import classes from './styles.module.css'

const ResultsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const diamond = useSelector(selectDiamond)
  const [isNotFound, setIsNotFound] = useState(false)
  const id = params.id

  const handleSearch = (id) => {
    navigate(`/search/${encodeURIComponent(id)}`)
  }

  const searchDIamond = (id) => {
    setIsNotFound(false)

    dispatch(getDiamondAction(id))
      .then((response) => {
        if (!response) {
          setIsNotFound(true)
        }
      })
      .catch(() => {
        setIsNotFound(true)
      })
  }

  useEffect(() => {
    searchDIamond(id)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.resultsPageContainer}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Searchbar value={id} withSearch onSubmit={handleSearch} />
        </div>
        {isNotFound ? (
          <div className={classes.noResults}>
            <NoResults id={id} />
          </div>
        ) : null}
        {diamond ? (
          <div className={classes.viewer}>
            <div className={classes.positioner}>
              <div className={classes.diamond}>
                <Diamond diamond={diamond} />
              </div>
            </div>
          </div>
        ) : null}

        <div className={classes.footer}>
          <div className={classes.disclaimer}>
            <Disclaimer />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default ResultsPage
