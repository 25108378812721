import API from 'api'
import { DIAMONDS_ACTION_TYPES } from '../actionTypes'

export function getDiamondAction(id) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.REQUEST, payload: id })

    return API.diamonds.getDiamond(id).then(
      (response) => {
        dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.COMPLETE,
          payload: response.content,
        })

        return response.content
      },
      (error) => {
        dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_DIAMOND.FAILURE,
          payload: error,
        })

        throw error
      }
    )
  }
}

export function getShareDiamondsAction(id) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARE_DIAMONDS.REQUEST, payload: id })

    return API.diamonds.getShareDiamonds(id).then(
      (response) => {
        dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_SHARE_DIAMONDS.COMPLETE,
          payload: response.content,
        })

        return response.content
      },
      (error) => {
        dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_SHARE_DIAMONDS.FAILURE,
          payload: error,
        })

        throw error
      }
    )
  }
}
