import React from 'react'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

import classes from './styles.module.css'

/**
 * List page layout
 * @reactProps {number} id page identifier
 * @reactProps {number} parentId parent page identifier
 */

export const DefaultLayout = function () {
  return (
    <div className={classes.defaultLayoutContainer}>
      <Outlet />
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.object,
}

export default DefaultLayout
