import React from 'react'
import classnames from 'classnames'

import { RoughLifecycleIcon, SplitLifecycleIcon, PolishedLifecycleIcon } from 'components/svg/llifecycles-new'

import classes from './styles.module.css'
import { testIds } from 'config'

export const Step = ({ lifecycle, carats }) => {
  let LifecycleIcon

  switch (lifecycle) {
    case 'rough':
      LifecycleIcon = RoughLifecycleIcon
      break
    case 'split':
      LifecycleIcon = SplitLifecycleIcon
      break
    default:
      LifecycleIcon = PolishedLifecycleIcon
      break
  }

  return (
    <div className={classes.stepContainer}>
      <div data-test-id={testIds.diamondExperience.softlinks[lifecycle]} className={classes.wrapper}>
        <div className={classnames(classes.icon, { [classes.polished]: lifecycle === 'polished' })}>
          <LifecycleIcon />
        </div>
        <div className={classes.title}>{lifecycle}</div>
        <div className={classes.carats}>{carats}</div>
      </div>
    </div>
  )
}

export default Step
