import React, { useState } from 'react'
import classnames from 'classnames'

import Media from 'containers/Media'
import { Spinner } from 'components'
import { resolveIconToSVG } from 'utils/svgResolver'
import { getOrderedMedia } from 'utils/diamond'

import classes from './styles.module.css'

export const HeaderImage = ({ diamond, ...props }) => {
  const [assetLoaded, setAssetLoaded] = useState(false)
  const media = getOrderedMedia(diamond, ['videos', 'images', 'lifecycle', 'shape'])[0]
  const Icon = media.type === 'icon' ? resolveIconToSVG(media.variant, media.value) : null

  const handleAssetLoad = () => {
    setAssetLoaded(true)
  }

  if (media.type === 'icon' && !assetLoaded) setAssetLoaded(true)

  return (
    <div className={classes.headerImageContainer}>
      {!assetLoaded ? <Spinner /> : null}
      <div {...props} className={classnames(classes.media, { [classes.visible]: assetLoaded })}>
        {media.type !== 'icon' ? (
          <Media media={{ id: diamond.id, ...media, local: diamond.is_mock, fov: 25 }} onLoad={handleAssetLoad} />
        ) : null}
        {media.type === 'icon' ? <Icon width={'100%'} height={'100%'} stroke={'#FFF'} /> : null}
      </div>
    </div>
  )
}

export default HeaderImage
