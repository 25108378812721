/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import classes from './styles.module.css'

const ThreeDAsset = ({
  asset,
  dummy,
  isThumbnail,
  scaled = true,
  fov = 45,
  defaultSurfaceColour = '#b0b0b0',
  defaultBackgroundColour = '#dedef0',
}) => {
  const containerElement = useRef()
  const [config, setConfig] = useState({
    autoRotate: true,
    surfaceColour: defaultSurfaceColour,
    backgroundColour: defaultBackgroundColour,
    directionalLight: 0.3,
  })
  const requestRef = React.useRef()

  // 1. Set-up renderer
  const renderer = useRef()

  const loadMesh = async asset => {
    let loader

    if (dummy || asset.extension === 'stl') {
      loader = new STLLoader()
    } else {
      loader = new PLYLoader()
    }

    const promise = new Promise(resolve => {
      loader.load(asset.data, geo => {
        resolve(geo)
      })
    })

    const geo = await promise

    return geo
  }

  useEffect(() => {
    if (!containerElement.current) {
      return
    }

    renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true })
    containerElement.current.appendChild(renderer.current.domElement)

    renderer.current.setPixelRatio(window.devicePixelRatio)
    renderer.current.setSize(containerElement.current.offsetWidth, containerElement.current.offsetHeight)

    renderer.current.domElement.setAttribute('style', 'outline: none')
  }, [])

  useEffect(() => {
    const handleResize = () => {
      renderer.current.setSize(containerElement.current.offsetWidth, containerElement.current.offsetHeight)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    // delayed resize event.
    setTimeout(() => {
      if (containerElement.current)
        renderer.current.setSize(containerElement.current.offsetWidth, containerElement.current.offsetHeight)
    }, 50)
  }, [])

  // 2. Set-up scene
  useEffect(() => {
    const fn = async () => {
      if (!containerElement.current) {
        return
      }

      let geometry = await loadMesh(asset)

      if (containerElement.current) {
        const scene = new THREE.Scene()
        scene.background = new THREE.Color('#fff')
        const camera = new THREE.PerspectiveCamera(
          fov, // initial fov
          containerElement.current.offsetWidth / containerElement.current.offsetHeight,
          0.1,
          1000
        )

        camera.position.x = 150
        camera.position.y = 150
        camera.position.z = 150

        camera.lookAt(new THREE.Vector3(0, 40, 0))

        const lightHolder = new THREE.Group()

        const directionalLight_1 = new THREE.DirectionalLight(0xffffff, config.directionalLight)
        directionalLight_1.position.set(1, 1, 0).normalize()
        lightHolder.add(directionalLight_1)

        const directionalLight_2 = new THREE.DirectionalLight(0xffffff, config.directionalLight)
        directionalLight_2.position.set(-1, -1, 0).normalize()
        lightHolder.add(directionalLight_2)
        scene.add(lightHolder)

        const light = new THREE.AmbientLight('#FFF')
        scene.add(light)

        containerElement.current.appendChild(renderer.current.domElement)

        let controls = null

        if (config.autoRotate) {
          controls = new OrbitControls(camera, renderer.current.domElement)
          // controls.enableZoom = true
          controls.enableZoom = false
          controls.autoRotate = true
          controls.enabled = true
        } else {
          controls = new TrackballControls(camera, renderer.current.domElement)
          controls.enableZoom = false
        }

        let group = new THREE.Object3D()
        const mat = new THREE.MeshLambertMaterial({
          color: config.surfaceColour,
        })

        geometry.center()
        geometry.computeBoundingBox()
        geometry.computeVertexNormals()

        const width = geometry.boundingBox.max.x - geometry.boundingBox.min.x
        const scale = containerElement.current.offsetWidth / width

        group = new THREE.Mesh(geometry, mat)
        group.scale.set(scale, scale, scale)
        if (scaled) {
          group.scale.multiplyScalar(0.2)
        }
        group.castShadow = true
        group.receiveShadow = true
        group.onAfterRender = () => {
          if (!isThumbnail) return

          const snapImg = document.createElement('IMG')
          const snapshot = renderer.current.domElement.toDataURL()
          snapImg.src = snapshot

          cancelAnimationFrame(requestRef.current)

          containerElement.current.removeChild(containerElement.current.children[0])
          containerElement.current.appendChild(snapImg)
        }
        scene.add(group)

        renderer.current.render(scene, camera)

        const render = () => {
          renderer.current.render(scene, camera)
        }

        render()

        controls.addEventListener('change', render)

        const animate = () => {
          if (group) {
            group.rotation.z += 0.006
          }

          controls.update()
          requestAnimationFrame(animate)
        }

        if (!isThumbnail) animate()
      }
    }

    fn()

    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [config.autoRotate, config.surfaceColour, config.backgroundColour, config.directionalLight])

  return <div className={classes.threeDAsset} ref={containerElement} />
}

ThreeDAsset.propTypes = {
  asset: PropTypes.object,
  isThumbnail: PropTypes.bool,
  scaled: PropTypes.bool,
  defaultBackgroundColour: PropTypes.string,
  defaultSurfaceColour: PropTypes.string,
}

export default ThreeDAsset
