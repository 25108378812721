import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import API from 'api'
import { GalleryLeftArrowIcon, GalleryRightArrowIcon } from 'components/svg'

import GallerySlide from '../GallerySlide'
import classes from './styles.module.css'
import { testIds } from 'config'

const DiamondGalleryCanvas = ({ asset, disableNav, section, onArrowClick, onAnimation, testIdPrefix }) => {
  const FADE_IN_OR_OUT_TIME = 350
  const [slideOne, setSlideOne] = useState(null)
  const [slideTwo, setSlideTwo] = useState(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(2)
  const [animating, setAnimating] = useState(false)

  const loadAsset = asset => {
    const nextSlideIndex = activeSlideIndex === 2 ? 1 : 2

    // first case when current slide already exists and needs to be replaced with a next one
    let currentSlide
    let setter
    let unsetter

    if (nextSlideIndex === 1) {
      currentSlide = slideTwo
      setter = setSlideOne
      unsetter = setSlideTwo
      setActiveSlideIndex(1)
    } else {
      currentSlide = slideOne
      setter = setSlideTwo
      unsetter = setSlideOne
      setActiveSlideIndex(2)
    }

    setter({ ...asset, loading: 1 })

    API.diamonds.getSignedUrl(asset.id, asset.hash)
      .then(data => {
        setAnimating(true)

        setter({ ...asset, data: data.content, loading: 2, fadeIn: !currentSlide })

        new Promise(resolve => {
          // no need to animate fade out for currentSlide if it doesn't exist
          if (!currentSlide) return resolve(false)

          // set current slide to fade out
          unsetter(currentState => ({ ...currentState, fadeIn: false }))

          setTimeout(() => {
            // remove unused slide from the dom & memory
            unsetter(null)
            resolve(true)
          }, FADE_IN_OR_OUT_TIME)
        })
          .then(shouldFadeIn => {
            return new Promise(resolve => {
              if (!shouldFadeIn) return resolve()

              setter(currentState => ({ ...currentState, fadeIn: true }))

              // give time for fade in animation to fimish
              setTimeout(() => {
                resolve()
              }, FADE_IN_OR_OUT_TIME)
            })
          })
          .then(() => {
            setAnimating(false)
          })
      })
      .catch(() => {
        setAnimating(false)
      })
  }

  useEffect(() => {
    if (!asset || animating) return

    loadAsset(asset)
  }, [JSON.stringify(asset)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // let parent component know that animation is in progress
    if (onAnimation) return onAnimation(animating)
  }, [animating]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.diamondGalleryCanvasContainer}>
      <div className={classes.horizontalSizer}>
        <div className={classes.verticalSizer}>
          <div className={classes.wrapper}>
            <div
              className={classnames(classes.button, 'left', { [classes.disabled]: disableNav })}
              onClick={() => !disableNav && onArrowClick('left')}
              data-test-id={`${testIdPrefix}.${testIds.gallery.leftArrowPostfix}`}
            >
              <GalleryLeftArrowIcon />
            </div>
            <div className={classes.canvas} data-test-id={`${testIdPrefix}.${testIds.gallery.mainAssetPostfix}`}>
              {slideOne ? (
                <div className={classes.slide}>
                  <GallerySlide asset={slideOne} animationTime={FADE_IN_OR_OUT_TIME} />
                </div>
              ) : null}
              {slideTwo ? (
                <div className={classes.slide}>
                  <GallerySlide asset={slideTwo} animationTime={FADE_IN_OR_OUT_TIME} />
                </div>
              ) : null}
            </div>
            <div
              className={classnames(classes.button, 'right', { [classes.disabled]: disableNav })}
              onClick={() => !disableNav && onArrowClick('right')}
              data-test-id={`${testIdPrefix}.${testIds.gallery.rightArrowPostfix}`}
            >
              <GalleryRightArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DiamondGalleryCanvas.propTypes = {
  asset: PropTypes.object.isRequired,
  disabledNav: PropTypes.bool,
  onArrowClick: PropTypes.func,
  onAnimation: PropTypes.func,
}

export default DiamondGalleryCanvas
