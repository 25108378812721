import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.css'

export const Spinner = () => {
  return (
    <div className={classes.spinner}>
      <div className={classnames(classes.bounce, classes.bounce1)}></div>
      <div className={classnames(classes.bounce, classes.bounce2)}></div>
    </div>
  )
}

export default Spinner
