import { combineReducers } from 'redux'

import versionReducer from './version'
import diamondsReducer from './diamonds'

const rootReducer = () =>
  combineReducers({
    version: versionReducer,
    diamonds: diamondsReducer,
  })

export default rootReducer
