import {
  FlagBotswanaSVG,
  FlagCanadaSVG,
  FlagDTCSVG,
  FlagNamibiaSVG,
  FlagSierraLeoneSVG,
  FlagSouthAfricaSVG,
  FlagNoProvenanceSVG,
} from 'components/svg/flags'

import {
  PrincessShapeSVG,
  AsscherShapeSVG,
  CushionShapeSVG,
  EmeraldShapeSVG,
  HeartShapeSVG,
  MarquiseShapeSVG,
  OvalShapeSVG,
  PearShapeSVG,
  RadiantShapeSVG,
  RoundShapeSVG,
  OtherShapeSVG,
} from 'components/svg/shapes'

import { cycles } from 'components/svg/lifecycle'
import { shapes } from 'components/svg/shapes-new'

export const resolveShapeToSVG = (shape) => {
  const shapeToSVG = {
    Princess: PrincessShapeSVG,
    Asscher: AsscherShapeSVG,
    Cushion: CushionShapeSVG,
    Emerald: EmeraldShapeSVG,
    Heart: HeartShapeSVG,
    Marquise: MarquiseShapeSVG,
    Oval: OvalShapeSVG,
    Pear: PearShapeSVG,
    Radiant: RadiantShapeSVG,
    Round: RoundShapeSVG,
    Other: OtherShapeSVG,
  }

  return shapeToSVG[shape] ? shapeToSVG[shape] : OtherShapeSVG
}

export const resolveProvenanceToSVG = (provenance) => {
  const countryToSVG = {
    Botswana: FlagBotswanaSVG,
    'South Africa': FlagSouthAfricaSVG,
    Canada: FlagCanadaSVG,
    Namibia: FlagNamibiaSVG,
    'Sierra Leone': FlagSierraLeoneSVG,
    DTC: FlagDTCSVG,
  }

  return countryToSVG[provenance] ? countryToSVG[provenance] : FlagNoProvenanceSVG
}

export const resolveIconToSVG = (variant, value) => {
  switch (variant) {
    case 'shape':
      return shapes[value] ? shapes[value] : shapes.Other
    case 'lifecycle':
      return cycles[value] ? cycles[value] : null
    default:
      return null
  }
}
