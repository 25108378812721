import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { Search } from 'components'
import { TracrLogoIcon, TracrLogoTextIcon } from 'components/svg'

import classes from './styles.module.css'

export const Searchbar = ({ value, withSearch, onSubmit }) => {
  const [logo, setLogo] = useState(<TracrLogoIcon />)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 833 || !withSearch) {
        setLogo(<TracrLogoTextIcon />)
      } else {
        setLogo(<TracrLogoIcon />)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.searchbarContainer}>
      <div className={classnames(classes.wrapper, { [classes.centerd]: !withSearch })}>
        <div className={classes.logo}>
          <a
            href={withSearch ? '/' : 'https://www.tracr.com/'}
            {...(!withSearch ? { target: '_blank', rel: 'noreferrer' } : null)}
          >
            {logo}
          </a>
        </div>
        {withSearch ? (
          <div className={classes.search}>
            <Search value={value} onSubmit={onSubmit} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Searchbar
