import React, { useEffect, useState } from 'react'

import API from 'api'
import MediaContent from 'containers/MediaContent'

const Media = ({ media, onLoad, children }) => {
  const [data, setData] = useState()

  const loadMedia = () => {
    API.diamonds
      .getSignedUrl(media.id, media.hash)
      .then((data) => {
        if (data) {
          setData(data.content)
        }
      })
      .catch(() => {
        // TODO: error handler and placeholder
        setData(null)
      })
      .finally(() => {
        if (onLoad) onLoad()
      })
  }

  useEffect(() => {
    loadMedia()
  }, [JSON.stringify(media)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!media) return null

  // return default component
  if (!children || children.length === 0) return <MediaContent asset={{ ...media, data }} />

  return React.cloneElement(children, {
    asset: { ...media, data },
    onLoad,
  })
}

export default Media
