import createActionSet from 'utils/createActionSet'

const namespace = 'DIAMONDS'
const GET_DIAMOND = createActionSet(namespace, 'GET_DIAMOND')
const GET_SHARE_DIAMONDS = createActionSet(namespace, 'GET_SHARE_DIAMONDS')

export const DIAMONDS_ACTION_TYPES = {
  GET_DIAMOND,
  GET_SHARE_DIAMONDS,
}
