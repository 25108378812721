import React from 'react'

import http from 'utils/http'
import { TracrLogoIcon } from 'components/svg'
import { getCertificateHash, isDeclaredDiamond } from 'utils/diamond'

import HeaderImage from './HeaderImage'
import InscriptionNumbers from './InscriptionNumbers'
import classes from './styles.module.css'
import { testIds } from 'config'

const getProvenanceCopy = (diamond) => {
  if (!diamond || !('provenance' in diamond)) return;
  const provenance = diamond.provenance;
  if (isDeclaredDiamond(diamond) || !provenance.provenance_name) {
    return null;
  } else if (provenance.provenance_type === "Entity" && provenance.provenance_name === "DTC") {
    return (
      <div data-test-id={testIds.diamondExperience.provenanceCopy} className={classes.provenance}>
        Discovered by <span>DTC</span>, which sources <span className={classes.unbreakable}>diamonds in</span>
        <br />
        <span>Botswana, Canada, Namibia</span> and <span>South Africa</span>
      </div>
    );
  } else {
    return (
      <div data-test-id={testIds.diamondExperience.provenanceCopy} className={classes.provenance}>
        Discovered in <span>{provenance.provenance_name}</span>
      </div>
    );
  }
}

export const Header = ({ diamond }) => {

  return (
    <div className={classes.headerContainer}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <TracrLogoIcon data-test-id={testIds.diamondExperience.tracrLogo} />
        </div>
        <div data-test-id={testIds.diamondExperience.tracrIdSection} className={classes.tracrId}>
          <div className={classes.heading}>Tracr ID</div>
          <div data-test-id={testIds.diamondExperience.tracrId} className={classes.id}>{diamond.diamond_id}</div>
        </div>
        <div className={classes.imageAndCopy}>
          <div className={classes.headerImage}>
            <HeaderImage data-test-id={testIds.diamondExperience.heroImage} diamond={diamond} />
          </div>
          <div className={classes.copy}>
            {getProvenanceCopy()}
            <div className={classes.blockchainLine}>
              This diamond is immutably registered on the Tracr blockchain platform
            </div>
            {getCertificateHash(diamond) ? (
              <a
                href={`${http.getBaseUrl()}/diamond/asset/${diamond.id}/${getCertificateHash(diamond)}/pdf`}
                target="_blank"
                rel="noreferrer"
                className={classes.viewCertBtn}
                data-test-id={testIds.diamondExperience.viewGradingReportButton}
              >
                View grading report
              </a>
            ) : null}

            <div className={classes.inscriptionNumbers}>
              <InscriptionNumbers diamond={diamond} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
