import React from 'react'

import { TracrLogoCircleIcon } from '../svg'
import classes from './styles.module.css'

export const Expired = () => {
  return (
    <footer className={classes.expiredContainer}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <TracrLogoCircleIcon />
        </div>
        <div className={classes.heading}>This share is no longer active</div>
        <div className={classes.description}>Access to this diamond view has now expired</div>
      </div>
    </footer>
  )
}

export default Expired
