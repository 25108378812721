import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import DiamondGalleryThumbnail from '../DiamondGalleryThumbnail'
import classes from './styles.module.css'

const DiamondGalleryThumbnails = ({ thumbnails, activeThumbnail, section, onThumbClick, onThumbsLoad }) => {
  const [loadState, setLoadState] = useState({})

  const onThumbLoad = (thumb, statusCode) => {
    setLoadState({ ...loadState, [thumb.hash]: statusCode })
  }

  useEffect(() => {
    const loadItemsKeys = Object.keys(loadState)

    // check if all items have finished loading
    if (loadItemsKeys.length !== thumbnails.length) return

    // check if one or all responses are unsuccessful
    if (loadItemsKeys.find((key) => loadState[key] !== 200)) {
      // check there's atleast one item that loaded successfully
      if (loadItemsKeys.find((key) => loadState[key] === 200)) {
        // number of successful items loaded
        if (onThumbsLoad) onThumbsLoad(loadItemsKeys.filter((key) => loadState[key] === 200).length)
      } else {
        if (onThumbsLoad) onThumbsLoad(0) // number of successful items loaded
      }
    } else {
      if (onThumbsLoad) onThumbsLoad(thumbnails.length) // number of successful items loaded
    }
  }, [Object.keys(loadState).length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.diamondGalleryThumbnailsContainer}>
      <div className={classes.wrapper}>
        <div className={classes.thumbnails} data-test-id={`${section}`}>
          {thumbnails.map((thumb, index) => (
            <React.Fragment key={thumb.filename + thumb.hash}>
              {index > 0 && index % 6 === 0 ? <div className={classes.breakLine} /> : null}
              <DiamondGalleryThumbnail
                asset={thumb}
                isActive={thumb === activeThumbnail}
                section={section}
                index={index}
                className={classes.thumbnail}
                onThumbClick={onThumbClick}
                onThumbLoad={onThumbLoad}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

DiamondGalleryThumbnails.propTypes = {
  thumbnails: PropTypes.array.isRequired,
  activeThumbnail: PropTypes.object,
  onThumbClick: PropTypes.func,
  onThumbsLoad: PropTypes.func,
}

export default DiamondGalleryThumbnails
