import React, { useEffect, useState, cloneElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { nanoid } from 'nanoid'

import { CloseIcon } from 'components/svg'

import classes from './styles.module.css'

export const Tooltip = ({
  title,
  secondaryTitle,
  description,
  descriptionMaxWidth = 300,
  variant = 'top',
  minWidth = 300,
  dynamicVariant = false,
  className,
  children,
  inline,
  inlineFixedWidth,
}) => {
  const [id] = useState(nanoid())
  const [finalVariant, setFinalVariant] = useState(variant)
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ left: -1000, top: -1000 })

  const getTooltip = () => {
    return (
      <div
        className={classnames(classes.tooltip, {
          [classes.top]: finalVariant === 'top',
          [classes.left]: finalVariant === 'left',
          [classes.bottom]: finalVariant === 'bottom',
          [classes.right]: finalVariant === 'right',
          [classes.center]: finalVariant === 'center',
          [classes.open]: open,
          [classes.tooltip]: true,
          [classes.inline]: inline
        })}
        style={{ left: `${position.left}px`, top: `${position.top}px`, width:`${inlineFixedWidth}px` }}
      >
        <div className={classes.close}>
          <CloseIcon />
        </div>

        {inline && (<>
          {title && (
            <span className={classes.title} style={{ minWidth: minWidth }}>
              {title} {(secondaryTitle || description) && <>: </>}
            </span>
          )}
          {secondaryTitle && (
            <span className={classes.secondaryTitle} style={{ minWidth: minWidth }}>
              {secondaryTitle} {description && <>: </>}
            </span>
          )}
          {description && (
            <span className={classes.description} style={{ maxWidth: `${descriptionMaxWidth}px` }}>
              {description}
            </span>
          )}
        </>)}
        {!inline && title && (
          <div className={classes.title} style={{ minWidth: minWidth }}>
            {title}
          </div>
        )}
        {!inline && secondaryTitle && (
          <div className={classes.secondaryTitle} style={{ minWidth: minWidth }}>
            {secondaryTitle}
          </div>
        )}
        {!inline && description && (
          <div className={classes.description} style={{ maxWidth: `${descriptionMaxWidth}px` }}>
            {description}
          </div>
        )}
      </div>
    )
  }

  const removeTooltip = () => {
    const element = document.getElementById(id)

    if (element) {
      element.parentNode.removeChild(element)
    }
  }

  const addTooltip = () => {
    const element = document.createElement('div')
    element.id = id
    element.innerHTML = renderToStaticMarkup(getTooltip())

    document.body.append(element)
  }

  useEffect(() => {
    const handleWindowClick = (evt) => {
      const parent = evt.target.closest('[data-tooltip-id]')

      if (!parent) return setOpen(false)

      const tooltipId = parent.dataset.tooltipId

      if (tooltipId === id) return

      setOpen(false)
    }

    const handleScroll = () => {
      setOpen(false)
    }

    if (open) {
      addTooltip()
      window.addEventListener('click', handleWindowClick)
      window.addEventListener('scroll', handleScroll)
    }

    if (!open) {
      removeTooltip()
      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('scroll', handleScroll)
    }

    return () => {
      removeTooltip()

      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  const composeEventHandler = (handler, eventHandler) => {
    return (event) => {
      if (eventHandler) {
        eventHandler(event)
      }

      handler(event)
    }
  }

  const calculateOffsets = (variant, rect) => {
    let left, top

    switch (variant) {
      case 'center':
        left = (window.innerWidth - (minWidth || descriptionMaxWidth)) / 2
        top = rect.y
        break
      case 'top':
        left = rect.x + rect.width / 2
        top = rect.y

        if (left < (minWidth || descriptionMaxWidth) / 2) {
          left = (minWidth || descriptionMaxWidth) / 2
        }
        break
      case 'left':
        left = rect.x
        top = rect.y + rect.height / 2
        break
      case 'right':
        left = rect.x + rect.width
        top = rect.y + rect.height / 2
        break
      case 'bottom':
        left = rect.x + rect.width / 2
        top = rect.y + rect.height
        break
      default:
    }

    return { top, left }
  }

  const getFinalVariant = (rect) => {
    if (!dynamicVariant) {
      return variant
    }

    const { left, top } = calculateOffsets(variant, rect)

    if (left + minWidth > window.innerWidth) {
      return 'center'
    }

    if (left + ((minWidth || descriptionMaxWidth) + 32) > window.innerWidth) {
      return 'left'
    }

    if (left - ((minWidth || descriptionMaxWidth) + 32) < 0) {
      return 'right'
    }

    if (top + 50 > window.innerHeight) {
      return 'top'
    }

    if (top < 0) {
      return 'bottom'
    }

    return variant
  }

  if (!title) {
    return children
  }

  return cloneElement(children, {
    'data-tooltip-id': id,
    ...(className ? { className: className } : null),
    onMouseOver: composeEventHandler(() => {}, children.onMouseOver),
    onMouseLeave: composeEventHandler(() => {}, children.onMouseLeave),
    onClick: composeEventHandler((e) => {
      if (!open) {
        const rect = document.querySelector(`[data-tooltip-id='${id}']`).getBoundingClientRect()
        const finalVariantResult = getFinalVariant(rect)

        const { left, top } = calculateOffsets(finalVariantResult, rect)

        setFinalVariant(finalVariantResult)
        setPosition({ left, top })
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, children.onClick),
  })
}

Tooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionMaxWidth: PropTypes.number,
  variant: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'center']),
  dynamicVariant: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export default Tooltip
