import React from 'react'

import { useMediaQuery } from 'components/utils/hooks'

import DesktopGallery from './Desktop'
import MobileGallery from './Mobile'

const GalleryIndex = ({ diamond, section, className, testIdPrefix }) => {
  const desktop = useMediaQuery('(min-width: 832px)')

  return (
    <>
      {desktop && <DesktopGallery testIdPrefix={testIdPrefix} diamond={diamond} section={section} className={className} />}
      {!desktop && <MobileGallery diamond={diamond} section={section} />}
    </>
  )
}

export default GalleryIndex
