import React from 'react'

import classes from './styles.module.css'

export const NotFound = ({ id = '' }) => {
  return (
    <div className={classes.notFoundContainer}>
      <div className={classes.wrapper}>
        <div className={classes.heading}>Sorry, we can’t retrieve a diamond with Tracr ID {id}</div>
        <div className={classes.description}>This Tracr ID could be private or may not exist</div>
      </div>
    </div>
  )
}

export default NotFound
