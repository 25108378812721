import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.css'

const ImageAsset = ({ asset, onLoad }) => {
  // can be improved by returning 404 template or Error template
  if (!asset) return null

  return <img className={classes.imageAsset} src={asset.data} alt={asset.description} onLoad={onLoad} />
}

ImageAsset.propTypes = {
  asset: PropTypes.object,
  onLoad: PropTypes.func,
}

export default ImageAsset
