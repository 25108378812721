import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";

import rootReducer from "./reducers/index";
import { STORE_INIT } from "./actionTypes";

const middleware = [thunk, save({ states: ["version"] })];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(),
  load({ states: ["version"] }), // load from localStorage
  composeEnhancers(applyMiddleware(...middleware))
);

// dispatch action to let all reducers know that store was initialized
// mostly needed for session reducer
store.dispatch({ type: STORE_INIT });

export default store;
