import { Provenance, getProvenanceName } from '@tracr/shared-components'

import Tooltip from 'components/Tooltip'
import classes from './ProvenanceWithTooltip.module.css'
import classNames from 'classnames'
import DarkTooltip from 'components/DarkToolTip'

const lockedToolipCopy = (lifecycle) => {
  switch (lifecycle) {
    case 'polished':
      return 'The provenance will be made available when you unlock the diamond.'
    default:
      return 'The provenance will be made available when you polish and unlock the diamond.'
  }
}

export const ProvenanceWithTooltip = ({
  provenance,
  lifecycle,
  asCell = false,
}) => {
  const toolip = provenance?.is_locked ? lockedToolipCopy(lifecycle) : getProvenanceName({ provenance })

  return (
    <DarkTooltip title={toolip} titleMaxWidth={280}>
      {asCell ? (
        <div className={classNames(classes.provenanceContainer, { [classes.centered]: false })}>
          <div className={classes.text}>
            <Provenance provenance={provenance} />
          </div>
        </div>
      ) : (
        <div>
            <Provenance provenance={provenance} />
        </div>
      )}
    </DarkTooltip>
  )
}
