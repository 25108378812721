import React from 'react'

import { InfoIcon } from 'components/svg'

import classes from './styles.module.css'

export const Disclaimer = () => {
  return (
    <div className={classes.disclaimerContainer}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <InfoIcon />
        </div>
        <div className={classes.text}>
          Please note that the data and content displayed on this webpage is controlled and owned by third parties and
          not by Tracr and that goods are sold and delivered by third parties, and not Tracr.
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
