import React from 'react'

import { Searchbar, Footer } from 'components'

import classes from './styles.module.css'

const PlaceholderPage = () => {
  return (
    <div className={classes.placeholderPageContainer}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Searchbar />
        </div>
        <div className={classes.placeholderMessage}>Coming soon...</div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default PlaceholderPage
