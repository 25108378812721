import React from 'react'

import { resolveIconToSVG } from 'utils/svgResolver'

import classes from './styles.module.css'

const DiamondGalleryPlaceholder = ({ diamond, lifecycle }) => {
  const Icon = resolveIconToSVG('lifecycle', lifecycle)

  if (!diamond || !Icon) {
    return null
  }

  return (
    <div className={classes.diamondPlaceholderContainer}>
      <Icon stroke="#FFF" />
    </div>
  )
}

export default DiamondGalleryPlaceholder
