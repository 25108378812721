import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.css'

const VideoAsset = ({ asset, extension, isThumbnail }) => {
  const containerRef = useRef()
  const videoRef = useRef()

  const handleSnapshot = () =>  {
    // use snapshot for video thumbnails
    if (isThumbnail && videoRef.current) {
      const video = videoRef.current
      const canvas = document.createElement('CANVAS')
      const context = canvas.getContext('2d')
      canvas.setAttribute('style', 'position: absolute; z-index: -100000; opacity: 0')

      document.body.insertBefore(canvas, document.body.children[0])

      video.currentTime = 0.1

      video.onloadeddata = () => {
        if (containerRef.current) {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          context.fillRect(0, 0, canvas.videoWidth, video.videoHeight)
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

          const dataUrl = canvas.toDataURL('image/png')
          const img = document.createElement('IMG')
          img.src = dataUrl

          containerRef.current.removeChild(videoRef.current)
          containerRef.current.appendChild(img)
        }

        document.body.removeChild(canvas)
      }
    }
  }

  useEffect(() =>{
    if(!videoRef.current) return

    handleSnapshot()

    videoRef.current.load()
    videoRef.current.play()
  }, [asset && asset.data]) // eslint-disable-line react-hooks/exhaustive-deps

  // can be improved by returning 404 template or Error template
  if (!asset) return null

  return (
    <div className={classes.videoAsset} ref={containerRef}>
      <video autoPlay={!isThumbnail} muted loop ref={videoRef}>
        <source src={asset.data} type={`video/${extension}`} />
      </video>
    </div>
  )
}

VideoAsset.propTypes = {
  asset: PropTypes.object,
  extension: PropTypes.string,
  isThumbnail: PropTypes.bool,
}

export default VideoAsset
