import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import DiamondGalleryPlaceholder from '../Desktop/DiamondGalleryPlaceholder'
import Media from 'containers/Media'
import { getOrderedMedia, getRootDiamond, isSectionVisible } from 'utils/diamond'

import classes from './styles.module.css'

const MobileGallery = ({ diamond, section }) => {
  const [paddingLeft, setPaddingLeft] = useState(0)
  const [marginRight, setMarginRight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  const [galleryWidth, setGalleryWidth] = useState(0)
  const [scrollPoints, setScrollPoints] = useState([])
  const [touchActive, setTouchActive] = useState(null)
  const [slides, setSlides] = useState([])
  const container = useRef(null)
  const scrollableArea = useRef(null)

  const mapAssets = (diamond, lifecycle) => {
    const assetsList = getOrderedMedia({ ...diamond, lifecycle_state: lifecycle }, [
      'videos',
      'images',
      'scans',
      'plans',
    ])

    return assetsList.map((asset) => ({
      id: diamond.id,
      ...asset,
      local: !!diamond.is_mock,
      thumb: asset.type !== 'video',
    }))
  }

  useEffect(() => {
    const galleryDiamond = section === 'rough' ? getRootDiamond(diamond) : diamond.current
    setSlides(mapAssets(galleryDiamond, section))
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  const calculateDimensions = () => {
    const maxImageWidth = 432
    const containerWidth = (container.current && container.current.clientWidth) || 0

    if (containerWidth < maxImageWidth + 48) {
      const marginRight = 8
      const padding = 24
      const imageWidth = containerWidth - padding * 2
      const imageWidthWithMargin = imageWidth + marginRight
      const galleryWidth = padding * 2 + imageWidthWithMargin * slides.length - marginRight

      setPaddingLeft(padding)
      setMarginRight(marginRight)
      setImageWidth(imageWidth)
      setGalleryWidth(galleryWidth)
      setScrollPoints(
        slides.map((x, index) => {
          if (index === 0) {
            return 0
          }

          return index * imageWidthWithMargin
        })
      )

      return
    }

    const marginRight = 16
    const imageWidth = maxImageWidth
    const imageWidthWithMargin = imageWidth + marginRight
    const padding = containerWidth / 2 - imageWidth / 2
    const galleryWidth = padding * 2 + imageWidthWithMargin * slides.length - marginRight

    setPaddingLeft(padding)
    setMarginRight(marginRight)
    setImageWidth(imageWidth)
    setGalleryWidth(galleryWidth)
    setScrollPoints(
      slides.map((x, index) => {
        if (index === 0) {
          return 0
        }

        return index * imageWidthWithMargin
      })
    )
  }

  const getClosest = (x) =>
    scrollPoints.length && scrollPoints.reduce((prev, curr) => (Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev))

  const scrollToPosition = () => {
    if (scrollableArea.current) {
      if (!touchActive) {
        const closestPosition = getClosest(scrollableArea.current.scrollLeft)
        scrollableArea.current.scrollTo({ top: 0, left: closestPosition, behavior: 'smooth' })
      }
    }
  }

  const debounceToPosition = debounce(() => scrollToPosition(), 300)

  const handleTouchStart = () => setTouchActive(true)
  const handleTouchEnd = () => {
    setTouchActive(false)
    debounceToPosition()
  }

  useEffect(() => {
    if (touchActive === false) {
      debounceToPosition()
    }
  }, [touchActive, debounceToPosition])

  useEffect(() => calculateDimensions(), [JSON.stringify(slides)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('resize', calculateDimensions)
    return () => {
      window.removeEventListener('resize', calculateDimensions)
    }
  }, [slides, scrollPoints]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamond || !isSectionVisible({ fullDiamond: diamond, section })) return null

  if (slides.length === 0) {
    return (
      <DiamondGalleryPlaceholder
        lifecycle={section}
        diamond={section === 'rough' ? getRootDiamond(diamond) : diamond.current}
      />
    )
  }

  return (
    <div className={classes.mobileGalleryContainer} ref={container}>
      <div className={classes.galleryContainer} style={{ height: `${imageWidth}px` }}>
        <div className={classes.gallery} ref={scrollableArea}>
          <div
            className={classes.galleryInner}
            style={{
              width: `${galleryWidth}px`,
              height: `${imageWidth}px`,
              paddingLeft: `${paddingLeft}px`,
              paddingRight: `${paddingLeft}px`,
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {slides.map((slide, index) => {
              const hasMargin = slides.length - 1 !== index

              return (
                <div
                  className={classes.gallerySlide}
                  style={{
                    width: `${imageWidth}px`,
                    height: `${imageWidth}px`,
                    marginRight: `${hasMargin ? marginRight : ''}px`,
                  }}
                  key={`mobile-gallery-slide-${index}`}
                >
                  <div className={classes.gallerySlideMedia}>
                    <Media media={slide} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

MobileGallery.propTypes = {
  diamond: PropTypes.object,
  section: PropTypes.string.isRequired,
}

export default MobileGallery
