import DB from "db";

import http from "./http";

class AssetLoader {
  constructor() {
    this.requests = [];
  }

  request(id, hash) {
    return http.get(`diamond/asset/${id}/${hash}`, null, true);
  }

  // load = true if media should be loaded from public/images folder
  load(diamondId, { hash, extension }) {
    let request = this.requests.find((request) => {
      return request.id === diamondId && request.hash === hash;
    });

    if (request) return request.promise;

    request = {
      id: diamondId,
      hash,
    };

    request.promise = new Promise((resolve, reject) => {
      if (!hash) return resolve(null);

      DB.get(hash)
        .then((dbAsset) => {
          if (dbAsset) return dbAsset;

          // asset is not in the local db, load it from api and put it into local db
          return this.request(diamondId, hash).then((response) => {
            return response.arrayBuffer().then((buffer) => {
              return DB.put(hash, buffer).then(() => buffer);
            });
          });
        })
        .then((data) => {
          return this.read(data, extension);
        })
        .then((data) => {
          this.removeFinishedRequest(request);

          resolve(data);
        });
    });

    this.requests.push(request);

    return request.promise;
  }

  read = (data, extension) => {
    return new Promise((resolve, reject) => {
      // non stl/ply files have to be decoded into base64 strings
      // to be used for src attribute
      if (!/^(ply|stl)$/.test(extension)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.readAsDataURL(new Blob([data]));
      } else {
        resolve(data);
      }
    });
  };

  removeFinishedRequest(request) {
    const index = this.requests.findIndex((iRequest) => {
      return request.id === iRequest.id && request.hash === iRequest.hash;
    });

    this.requests.splice(index, 1);
  }

  // checks if record is present in the db
  isRecordAvailable(hash) {
    if (!hash) return Promise.resolve(false);

    return new Promise((resolve, reject) => {
      DB.get(hash).then((dbAsset) => {
        if (dbAsset) return resolve(true);

        return resolve(false);
      });
    });
  }
}

export const assetLoader = new AssetLoader();

export default assetLoader;
