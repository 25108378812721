import React from 'react'

import { InfoIcon } from 'components/svg'

import classes from './styles.module.css'

export const LabelAndValue = ({ label, value }) => {
  if (!label || !value) return null

  return (
    <div className={classes.labelAndValueContainer}>
      <div className={classes.wrapper}>
        <div className={classes.label}>
          {label}&nbsp;
          <InfoIcon />
        </div>

        <div className={classes.value}>{value}</div>
      </div>
    </div>
  )
}

export default LabelAndValue
