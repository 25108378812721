import env from '@beam-australia/react-env'

class Http {
  constructor() {
    this.API_URL = env('API_URL') || 'http://localhost:4000/api/v1'
  }

  getBaseUrl() {
    return this.API_URL
  }

  validateBody(body, format) {
    // let all formats other than json be handled by axios
    if ((format && format !== 'json') || !body) return body

    const parsed = JSON.stringify(body)

    // do not send empty data to BE
    if (parsed.length === 0 || parsed === '{}' || parsed === '[]') return undefined

    return body
  }

  get(url, query) {
    return fetch(`${this.API_URL}/${url}`, {
      method: 'GET',
      params: query,
    })
  }

  post(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return fetch(`${this.API_URL}/${url}`, {
      method: 'POST',
      body: validatedBody,
    })
  }

  downloadFile(url, filename, inBlankPage) {
    return fetch(`${this.API_URL}/${url}`, {
      responseType: 'blob',
      timeout: 30000,
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)

        a.style.display = 'none'
        a.href = url

        if (inBlankPage) {
          a.target = '_blank'
        } else {
          a.download = filename
        }

        a.click()

        window.URL.revokeObjectURL(url)

        document.body.removeChild(a)
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

const http = new Http()

export default http
