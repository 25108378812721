import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import API from 'api'
import MediaContent from 'containers/MediaContent'
import { Spinner } from 'components'

import classes from './styles.module.css'

const DiamondGalleryThumbnail = ({ asset, isActive, index, section, className, onThumbClick, onThumbLoad }) => {
  const [thumb, setThumb] = useState({ ...asset, thumb: true, data: null, loading: null, fov: 8 })

  const loadAsset = (asset) => {
    setThumb({ ...thumb, loading: 1 })

    API.diamonds
      .getSignedUrl(asset.id, asset.hash)
      .then((data) => {
        if (data) {
          setThumb({ ...thumb, data: data.content, loading: 2 })
        } else {
          setThumb({ ...thumb, loading: 0 })
        }

        if (onThumbLoad) onThumbLoad(asset, 200)
      })
      .catch(() => {
        setThumb({ ...thumb, loading: 3 })

        // TODO: error handler and placeholder
        if (onThumbLoad) onThumbLoad(asset, 404)
      })
  }

  useEffect(() => {
    if (!asset) return

    loadAsset(asset)
  }, [JSON.stringify(asset)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames(classes.diamondGalleryThumbnailContainer, className)}>
      <div className={classes.wrapper}>
        <div className={classes.positioner}>
          <div
            className={classnames(classes.thumbnail, { [classes.active]: isActive })}
            onClick={() => onThumbClick(asset)}
            data-test-id={`${index}-${section}`}
          >
            <>
              <div className={classnames(classes.loader, { [classes.visible]: thumb.loading === 1 })}>
                <Spinner />
              </div>
              <MediaContent asset={thumb} />
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

DiamondGalleryThumbnail.propTypes = {
  asset: PropTypes.object,
  empty: PropTypes.bool,
  isActive: PropTypes.bool,
  index: PropTypes.number,
  onThumbClick: PropTypes.func,
  onThumbLoad: PropTypes.func,
}

export default DiamondGalleryThumbnail
