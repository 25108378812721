import React from 'react'

export const Rough = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M102.805 112.588L70.9831 132.692M102.805 112.588L70.9831 178.134M102.805 112.588L176.373 132.692M102.805 112.588L161.877 64M234.659 69.9669L280.517 132.692M234.659 69.9669L340.551 178.134M234.659 69.9669L161.877 64M340.551 178.134L273.517 252.385M340.551 178.134L234.659 232.149M340.551 178.134L280.517 132.692M273.517 252.385L132.567 232.149M273.517 252.385L234.659 232.149M60 210.616L70.9831 132.692M60 210.616L70.9831 178.134M60 210.616L83.4503 224.681L132.567 232.149M70.9831 132.692V178.134M234.659 232.149L70.9831 178.134M234.659 232.149H132.567M234.659 232.149L176.373 132.692M234.659 232.149L280.517 132.692M70.9831 178.134L132.567 232.149M70.9831 178.134L176.373 132.692M176.373 132.692H280.517M176.373 132.692L161.877 64M280.517 132.692L161.877 64"
        stroke="white"
      />
    </svg>
  )
}

export const Split = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M64.3268 121.793L72.0043 136.33M64.3268 121.793L76.5715 160.005M64.3268 121.793L78.5516 105.551L187.985 55.9956L210.224 50.803L313.889 86.6587L339.817 100.558M339.817 100.558L346.724 121.165M339.817 100.558L339.816 117.6M200.254 203.297L220.551 270.129M200.254 203.297L163.451 246.184M200.254 203.297L255.853 210.164M200.254 203.297L102.109 161.792M200.254 203.297L272.601 171.984M220.551 270.129L255.853 210.164M220.551 270.129L299.057 187.27M220.551 270.129L163.451 246.184M163.451 246.184L102.109 161.792M163.451 246.184L76.5715 160.005M255.853 210.164L299.057 187.27M255.853 210.164L272.601 171.984M102.109 161.792L72.0043 136.33M299.057 187.27L313.887 132.263M299.057 187.27L339.815 142.469L346.724 121.165M272.601 171.984L313.887 132.263M313.887 132.263L339.816 117.6M346.724 121.165L339.816 117.6M72.0043 136.33L76.5715 160.005"
        stroke="white"
      />
    </svg>
  )
}

export const Polished = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M198.333 260.483L338.828 136.406M198.333 260.483L59.1641 136.406M198.333 260.483L254.001 136.406M198.333 260.483V218.244M198.333 260.483L148.63 136.406M198.333 260.483L166.523 218.244M198.333 260.483L234.782 218.244M338.828 136.406V127.826M338.828 136.406H328.224M338.828 127.826L320.272 110.007M338.828 127.826H328.224M320.272 110.007L279.184 90.8672M320.272 110.007L328.224 127.826M279.184 90.8672H234.782M279.184 90.8672L284.485 110.007M118.808 90.8672L77.7199 110.007M118.808 90.8672H166.523M118.808 90.8672L117.104 110.007M77.7199 110.007L59.1641 127.826M77.7199 110.007L71.0928 127.826M59.1641 127.826V136.406M59.1641 127.826H71.0928M59.1641 136.406H71.0928M198.333 110.007L234.782 90.8672M198.333 110.007L254.001 127.826M198.333 110.007L166.523 90.8672M198.333 110.007L148.63 127.826M198.333 110.007V127.826M234.782 90.8672L284.485 110.007M234.782 90.8672H166.523M284.485 110.007L328.224 127.826M284.485 110.007L296.414 127.826M284.485 110.007L254.001 127.826M328.224 127.826H296.414M296.414 127.826H254.001M254.001 127.826H198.333M166.523 90.8672L117.104 110.007M71.0928 127.826L117.104 110.007M71.0928 127.826H102.903M117.104 110.007L102.903 127.826M117.104 110.007L148.63 127.826M102.903 127.826H148.63M148.63 127.826H198.333M71.0928 136.406H102.903M71.0928 136.406L166.523 218.244M102.903 136.406H148.63M102.903 136.406L166.523 218.244M148.63 136.406H198.333M148.63 136.406L198.333 218.244M148.63 136.406L166.523 218.244M198.333 136.406H254.001M198.333 136.406V127.826M198.333 136.406V218.244M254.001 136.406H296.414M254.001 136.406L234.782 218.244M254.001 136.406L198.333 218.244M296.414 136.406H328.224M296.414 136.406L234.782 218.244M328.224 136.406L234.782 218.244"
        stroke="white"
      />
    </svg>
  )
}

export const Planning = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M12.0173 2.76831L4.82401 11.1605L11.171 12.9848M12.0173 2.76831L19.2106 11.1605L11.171 12.9848M12.0173 2.76831L11.171 12.9848M12.4404 22.1067L11.4383 16.6336M12.4404 22.1067L18.3643 14.8092L11.4383 16.6336M12.4404 22.1067L5.24714 14.8092L11.4383 16.6336"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M3.13138 12.6202L11.1709 14.8095L20.903 12.6202"
      stroke={stroke}
      strokeDasharray="2 2"
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Sawing = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M62.5 168.75L139.706 189.583M62.5 168.75L155.147 268.75M62.5 168.75L155.147 152.083L237.5 168.75M139.706 189.583L237.5 168.75M139.706 189.583L155.147 268.75M237.5 168.75L155.147 268.75"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M144.853 31.2497L62.5 131.25L144.853 147.916M144.853 31.2497L237.5 131.25L144.853 147.916M144.853 31.2497V147.916"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Bruting = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M59.7222 51.1168L103.641 77.3788M59.7222 51.1168L103.641 31.5473L146.434 25L189.227 31.5473L233.333 51.1168M59.7222 51.1168L25 96.8213M103.641 77.3788L146.434 83.9261M103.641 77.3788L73.6111 129.467M146.434 83.9261L196.359 70.8314M146.434 83.9261V149.055M196.359 70.8314L233.333 51.1168M196.359 70.8314L219.444 129.467M233.333 51.1168L275 96.8213M25 96.8213L59.7222 195.231L115.278 246.993M25 96.8213L73.6111 129.467M115.278 246.993L146.434 262.5M115.278 246.993L73.6111 129.467M146.434 262.5L182.095 247.61M146.434 262.5V227.968V149.055M182.095 247.61L233.333 195.231L275 96.8213M182.095 247.61L219.444 129.467M275 96.8213L219.444 129.467M219.444 129.467L146.434 149.055M146.434 149.055L73.6111 129.467"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Polishing = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M25 95.1923L95.5128 127.244M25 95.1923L63.4615 50.3205M25 95.1923L146.795 255.449M95.5128 127.244H204.487M95.5128 127.244L146.795 255.449M95.5128 127.244L101.923 69.5513M204.487 127.244L275 95.1923M204.487 127.244L191.667 69.5513M204.487 127.244L146.795 255.449M275 95.1923L230.128 50.3205M275 95.1923L146.795 255.449M230.128 50.3205L178.846 37.5H114.744L63.4615 50.3205M230.128 50.3205L191.667 69.5513M63.4615 50.3205L101.923 69.5513M101.923 69.5513H191.667"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Graded = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M18.6188 2.5H21.2643V19.1107H18.6188M18.6188 2.5V19.1107M18.6188 2.5H4.86205V21.0187L3.96606 22.2854H17.8812L18.6188 21.0187V19.1107"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M7.50726 11.0711L8.91821 9.37793H14.562L15.9729 11.0711M7.50726 11.0711L11.7401 15.7272L15.9729 11.0711M7.50726 11.0711H11.7401H15.9729"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const cycles = {
  rough: Rough,
  split: Split,
  planning: Planning,
  sawing: Sawing,
  bruting: Bruting,
  polishing: Polishing,
  polished: Polished,
  graded: Graded,
}
