export const testIds = {
  landingPage: {},
  gallery: {
    leftArrowPostfix: 'arrowLeft',
    rightArrowPostfix: 'arrowRight',
    mainAssetPostfix: 'mainAsset',
  },
  diamondExperience: {
    viewHideDiamondDetailsButton: 'diamondExperience.viewHideDiamondDetailsButton',
    headerTitle: 'diamondExperience.diamondsNav.title',
    tracrId: 'diamondExperience.tracrId',
    tracrIdSection: 'diamondExperience.tracrIdSection',
    tracrLogo: 'diamondExperience.tracrLogo',
    viewGradingReportButton: 'diamondExperience.viewGradingReportButton',
    backToTopButton: 'diamondExperience.backToTopButton',
    unlockButton: 'diamondExperience.unlockButton',
    heroImage: 'diamondExperience.heroImage',
    provenanceCopy: 'diamondExperience.provenanceCopy',
    inscriptionNumberSection: 'diamondExperience.inscriptionNumberSection',
    details: {
      prefix: 'diamondExperience.details',
    },
    softlinks: {
      title: 'diamondExperience.softlinks.title',
      rough: 'diamondExperience.softlinks.rough',
      split: 'diamondExperience.softlinks.split',
      polished: 'diamondExperience.softlinks.polished',
    },
  },
}

export default testIds
