import http from '../utils/http'

import diamond from './diamond.json'

const namespace = 'diamond'

const getDiamond = (id) => {
  if (id === 'mock') return Promise.resolve({ content: diamond })

  return http.get(`${namespace}/search/${id}/full`).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw response
    }
  })
}

const getShareDiamonds = (id) => {
  return http.get(`share/${id}`).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw response
    }
  })
}

const getSignedUrl = (id, hash) => {
  return http.get(`${namespace}/asset/${id}/${hash}`, null).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw response
    }
  })
}

const diamonds = {
  getDiamond,
  getShareDiamonds,
  getSignedUrl,
}

export default diamonds
