import React from 'react'

export const RoughLifecycleIcon = () => {
  return (
    <svg width="88" height="60" viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2348 16.1456L4.56735 22.2532M14.2348 16.1456L4.56735 36.0585M14.2348 16.1456L36.5844 22.2532M14.2348 16.1456L32.1808 1.38477M54.2917 3.19751L68.2231 22.2532M54.2917 3.19751L86.4615 36.0585M54.2917 3.19751L32.1808 1.38477M86.4615 36.0585L66.0966 58.6155M86.4615 36.0585L54.2917 52.4679M86.4615 36.0585L68.2231 22.2532M66.0966 58.6155L23.2763 52.4679M66.0966 58.6155L54.2917 52.4679M1.23071 45.9264L4.56735 22.2532M1.23071 45.9264L4.56735 36.0585M1.23071 45.9264L8.35487 50.1992L23.2763 52.4679M4.56735 22.2532V36.0585M54.2917 52.4679L4.56735 36.0585M54.2917 52.4679H23.2763M54.2917 52.4679L36.5844 22.2532M54.2917 52.4679L68.2231 22.2532M4.56735 36.0585L23.2763 52.4679M4.56735 36.0585L36.5844 22.2532M36.5844 22.2532H68.2231M36.5844 22.2532L32.1808 1.38477M68.2231 22.2532L32.1808 1.38477"
        stroke="white"
      />
    </svg>
  )
}

export const SplitLifecycleIcon = () => {
  return (
    <svg width="87" height="69" viewBox="0 0 87 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.611354 22.8179L2.94375 27.2341M0.611354 22.8179L4.33126 34.4268M0.611354 22.8179L4.93279 17.8837L38.1784 2.82886L44.9345 1.25135L76.4276 12.1442L84.3044 16.3669M84.3044 16.3669L86.403 22.6273M84.3044 16.3669L84.3043 21.5441M41.9056 47.5787L48.0719 67.8822M41.9056 47.5787L30.725 60.6076M41.9056 47.5787L58.7966 49.6648M41.9056 47.5787L12.0893 34.9697M41.9056 47.5787L63.8845 38.0659M48.0719 67.8822L58.7966 49.6648M48.0719 67.8822L71.9218 42.7097M48.0719 67.8822L30.725 60.6076M30.725 60.6076L12.0893 34.9697M30.725 60.6076L4.33126 34.4268M58.7966 49.6648L71.9218 42.7097M58.7966 49.6648L63.8845 38.0659M12.0893 34.9697L2.94375 27.2341M71.9218 42.7097L76.4272 25.9987M71.9218 42.7097L84.304 29.0992L86.403 22.6273M63.8845 38.0659L76.4272 25.9987M76.4272 25.9987L84.3043 21.5441M86.403 22.6273L84.3043 21.5441M2.94375 27.2341L4.33126 34.4268"
        stroke="white"
      />
    </svg>
  )
}

export const PolishedLifecycleIcon = () => {
  return (
    <svg width="87" height="54" viewBox="0 0 87 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.6441 52.3847L86.4615 14.5709M43.6441 52.3847L1.23071 14.5709M43.6441 52.3847L60.6095 14.5709M43.6441 52.3847V39.5119M43.6441 52.3847L28.4965 14.5709M43.6441 52.3847L33.9496 39.5119M43.6441 52.3847L54.7524 39.5119M86.4615 14.5709V11.9561M86.4615 14.5709H83.23M86.4615 11.9561L80.8064 6.52537M86.4615 11.9561H83.23M80.8064 6.52537L68.2843 0.692383M80.8064 6.52537L83.23 11.9561M68.2843 0.692383H54.7524M68.2843 0.692383L69.9001 6.52537M19.4079 0.692383L6.88584 6.52537M19.4079 0.692383H33.9496M19.4079 0.692383L18.8885 6.52537M6.88584 6.52537L1.23071 11.9561M6.88584 6.52537L4.86615 11.9561M1.23071 11.9561V14.5709M1.23071 11.9561H4.86615M1.23071 14.5709H4.86615M43.6441 6.52537L54.7524 0.692383M43.6441 6.52537L60.6095 11.9561M43.6441 6.52537L33.9496 0.692383M43.6441 6.52537L28.4965 11.9561M43.6441 6.52537V11.9561M54.7524 0.692383L69.9001 6.52537M54.7524 0.692383H33.9496M69.9001 6.52537L83.23 11.9561M69.9001 6.52537L73.5355 11.9561M69.9001 6.52537L60.6095 11.9561M83.23 11.9561H73.5355M73.5355 11.9561H60.6095M60.6095 11.9561H43.6441M33.9496 0.692383L18.8885 6.52537M4.86615 11.9561L18.8885 6.52537M4.86615 11.9561H14.5606M18.8885 6.52537L14.5606 11.9561M18.8885 6.52537L28.4965 11.9561M14.5606 11.9561H28.4965M28.4965 11.9561H43.6441M4.86615 14.5709H14.5606M4.86615 14.5709L33.9496 39.5119M14.5606 14.5709H28.4965M14.5606 14.5709L33.9496 39.5119M28.4965 14.5709H43.6441M28.4965 14.5709L43.6441 39.5119M28.4965 14.5709L33.9496 39.5119M43.6441 14.5709H60.6095M43.6441 14.5709V11.9561M43.6441 14.5709V39.5119M60.6095 14.5709H73.5355M60.6095 14.5709L54.7524 39.5119M60.6095 14.5709L43.6441 39.5119M73.5355 14.5709H83.23M73.5355 14.5709L54.7524 39.5119M83.23 14.5709L54.7524 39.5119"
        stroke="white"
      />
    </svg>
  )
}
