import React from 'react'

import { LongArrowRightSmallIcon, LongArrowRightMediumIcon, LongArrowRightLargeIcon } from 'components/svg'

import Step from './Step'
import classes from './styles.module.css'

export const Journey = ({ sections }) => {
  const steps = sections.map((section) => {
    const carats = section.props.find((prop) => prop.label === 'Carat')

    return {
      lifecycle: section.lifecycle,
      carats: carats.value,
    }
  })

  const handleStepClick = (index) => {
    const lifecycle = steps[index].lifecycle

    document.getElementById(`${lifecycle}Section`).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={classes.journeyContainer}>
      <div className={classes.wrapper}>
        {steps.map((step, index) => {
          const isLast = index === steps.length - 1

          return (
            <React.Fragment key={`step-${step.lifecycle}`}>
              <div className={classes.step} onClick={() => handleStepClick(index)}>
                <Step {...step} isLast={index === steps.length - 1} />
              </div>
              {!isLast ? (
                <div className={classes.spacer} key={`spacer${index}`}>
                  <div className={classes.mobile}>
                    <LongArrowRightSmallIcon />
                  </div>
                  <div className={classes.tablet}>
                    <LongArrowRightMediumIcon />
                  </div>
                  <div className={classes.desktop}>
                    <LongArrowRightLargeIcon />
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default Journey
