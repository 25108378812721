import env from '@beam-australia/react-env'

export * from './assetLoader'

/**
 * Parses comma separated string into an array
 * @param {String} Comma separated values
 * @returns
 */
export const parseCsvEnvVar = (variable) => {
  const value = env(variable) || ''

  if (!value) return []

  return value.replace(/\s/g, '').split(',')
}
