import React, { createContext } from 'react'
import { BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import Wrapper from 'containers/Wrapper'
import Router from './Router'
import './global.css'

export const ConfigContext = createContext()

/**
 * Top level application component.
 * @reactProps {object} config Application config
 */
export const App = function ({ config = {} }) {
  // const APP_VERSION = env('VERSION') || 'NOT_SET'

  // INITIAL way version loggin, can be improved
  /*
  console.log(
    `%cApplication version: %c ${APP_VERSION}`,
    'background: #202020; color: #fff; font-size: 14px',
    'color: #202020; font-size: 14px'
  )
  */

  return (
    <BrowserRouter>
      <Wrapper>
        <Router />
      </Wrapper>
    </BrowserRouter>
  )
}

App.propTypes = {
  config: PropTypes.object,
}

export default App
