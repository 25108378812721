import React from 'react'

import classes from './styles.module.css'

export const Footer = () => {
  return (
    <footer className={classes.footerContainer}>
      <div className={classes.wrapper}>
        <ul className={classes.links}>
          <li>
            <a href="https://www.tracr.com/" target="_blank" rel="noreferrer">
              Learn about Tracr
            </a>
          </li>
          <li>
            <a href="https://www.tracr.com/legal/disclaimer" target="_blank" rel="noreferrer">
              Disclaimer
            </a>
          </li>
          <li>
            <a href="https://www.tracr.com/legal/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://www.tracr.com/diamond-experience/terms-and-conditions" target="_blank" rel="noreferrer">
              Terms & Conditions
            </a>
          </li>
        </ul>
        <div className={classes.copy}>&copy;Tracr 2023. All rights reserved</div>
      </div>
    </footer>
  )
}

export default Footer
