import React, { useEffect, useState, cloneElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { nanoid } from 'nanoid'

import classes from './styles.module.css'

/**
 * This is a copy of the tooltip from portal, suspose to be use for consistency
 */
export const DarkTooltip = ({
  title,
  titleMaxWidth = 300,
  description,
  descriptionMaxWidth = 300,
  variant = 'top',
  minWidth,
  dynamicVariant = false,
  className,
  children,
  inline,
  inlineFixedWidth,
}) => {
  const [id] = useState(nanoid())
  const [finalVariant, setFinalVariant] = useState(variant)
  const [isOpen, setIsOpen] = useState(false)
  const [position, setPosition] = useState({ left: -1000, top: -1000 })

  const getTooltip = () => {
    return (
      <div
        className={classnames(classes.tooltip, {
          [classes.top]: finalVariant === 'top',
          [classes.left]: finalVariant === 'left',
          [classes.bottom]: finalVariant === 'bottom',
          [classes.right]: finalVariant === 'right',
          [classes.open]: isOpen,
          [classes.tooltip]: true,
          [classes.inline]: inline,
        })}
        style={{ left: `${position.left}px`, top: `${position.top}px` }}
      >
        {inline && (
          <div style={{ width: `${inlineFixedWidth}px` }}>
            {title && (
              <span className={classes.title} style={{ minWidth: minWidth, maxWidth: `${titleMaxWidth}px` }}>
                {title}
                {description && <>: </>}
              </span>
            )}
            {description && (
              <span className={classes.description} style={{ maxWidth: `${descriptionMaxWidth}px` }}>
                {description}
              </span>
            )}
          </div>
        )}

        {!inline && title && (
          <div className={classes.title} style={{ minWidth: minWidth, maxWidth: `${titleMaxWidth}px` }}>
            {title}
          </div>
        )}
        {!inline && description && (
          <div className={classes.description} style={{ maxWidth: `${descriptionMaxWidth}px` }}>
            {description}
          </div>
        )}
      </div>
    )
  }

  const removeTooltip = () => {
    const element = document.getElementById(id)

    if (element) {
      element.parentNode.removeChild(element)
    }
  }

  const addTooltip = () => {
    const element = document.createElement('div')
    element.id = id
    element.innerHTML = renderToStaticMarkup(getTooltip())

    document.body.append(element)
  }

  useEffect(() => {
    if (isOpen) {
      addTooltip()
    }

    if (!isOpen) {
      removeTooltip()
    }

    return () => {
      removeTooltip()
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const composeEventHandler = (handler, eventHandler) => {
    return (event) => {
      if (eventHandler) {
        eventHandler(event)
      }

      handler(event)
    }
  }

  const calculateOffsets = (variant, rect) => {
    let left, top

    switch (variant) {
      case 'top':
        left = rect.x + rect.width / 2
        top = rect.y
        break
      case 'left':
        left = rect.x
        top = rect.y + rect.height / 2
        break
      case 'right':
        left = rect.x + rect.width
        top = rect.y + rect.height / 2
        break
      case 'bottom':
        left = rect.x + rect.width / 2
        top = rect.y + rect.height
        break
      default:
    }

    return { top, left }
  }

  const getFinalVariant = (rect) => {
    if (!dynamicVariant) {
      return variant
    }

    const { left, top } = calculateOffsets(variant, rect)

    if (left + (descriptionMaxWidth + 32) > window.innerWidth) {
      return 'left'
    }

    if (left - (descriptionMaxWidth + 32) < 0) {
      return 'right'
    }

    if (top + 50 > window.innerHeight) {
      return 'top'
    }

    if (top < 0) {
      return 'bottom'
    }

    return variant
  }

  if (!title) {
    return children
  }

  return cloneElement(children, {
    'data-tooltip-id': id,
    ...(className ? { className: className } : null),
    onMouseOver: composeEventHandler((e) => {
      if (!isOpen) {
        const rect = document.querySelector(`[data-tooltip-id='${id}']`).getBoundingClientRect()
        const finalVariantResult = getFinalVariant(rect)

        const { left, top } = calculateOffsets(finalVariantResult, rect)

        setFinalVariant(finalVariantResult)
        setPosition({ left, top })
        setIsOpen(true)
      }
    }, children.onMouseOver),
    onMouseLeave: composeEventHandler((e) => {
      setIsOpen(false)
    }, children.onMouseLeave),
  })
}

DarkTooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionMaxWidth: PropTypes.number,
  variant: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  dynamicVariant: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export default DarkTooltip
