import { DIAMONDS_ACTION_TYPES } from '../actionTypes'

const initialState = {
  data: {
    diamond: null,
  },
  requests: {
    loading: false,
    error: null,
  },
}

const diamondsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.REQUEST: {
      return {
        ...state,
        requests: {
          loading: true,
          error: null,
        },
        data: {
          ...state.data,
          diamond: null,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.FAILURE: {
      return {
        ...state,
        requests: {
          loading: false,
          error: payload.error,
        },
        data: {
          diamond: null,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMOND.COMPLETE: {
      return {
        ...state,
        requests: {
          loading: false,
          error: null,
        },
        data: {
          ...state.data,
          diamond: payload,
        },
      }
    }
    default:
      return state
  }
}

export default diamondsReducer
