import React from 'react'

import { NoResultsIcon } from '../svg'
import NotFound from '../NotFound'
import classes from './styles.module.css'

export const NoResults = ({ id = '' }) => {
  return (
    <div className={classes.noResultsContainer}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <NoResultsIcon />
        </div>
        <div className={classes.text}>
          <NotFound id={id} />
        </div>
      </div>
    </div>
  )
}

export default NoResults
