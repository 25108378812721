import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import MediaContent from 'containers/MediaContent'
import { Spinner } from 'components'

import classes from './styles.module.css'

const GallerySlide = ({ asset, animationTime = 350 }) => {
  const labels = {
    default: 'Default',
    face_up: 'Face up',
    '3d': '3D',
    heart: 'Heart',
    arrow: 'Arrow',
    ideal_scope: 'Ideal scope',
    aset_scope: 'Aset scope',
    dark_field: 'Dark field',
    fluorescence: 'Fluorescence',
    inclusion_map: 'Inclusion map',
    plot: 'Plot',
    b2b: 'b2b',
    diagram: 'Diagram',
    // other is intentionally omitted here. We do not want to show a label for these images.
  }

  const renderLabel = (asset) => {
    const supportedTypes = /png|jpg|gif|jpeg/i
    const imageType = labels[asset.image_type]
    const label = `${imageType} Image`

    if (!supportedTypes.test(asset.extension)) return null

    if (!imageType) return null

    return <div className={classes.label}>{label}</div>
  }

  if (!asset) return null

  return (
    <div className={classes.gallerySlideContainer}>
      <div className={classes.wrapper}>
        <div className={classnames(classes.loader, { [classes.visible]: asset.loading === 1 })}>
          <Spinner />
        </div>
        <div
          className={classnames(classes.slide, {
            [classes.fadedOut]: !asset.fadeIn,
            [classes.fadedIn]: asset.fadeIn,
          })}
          styles={{ transitionDuration: `${animationTime}ms` }}
        >
          <MediaContent asset={asset} />
          <div className={classes.labelWrapper}>{renderLabel(asset)}</div>
        </div>
      </div>
    </div>
  )
}

GallerySlide.propTypes = {
  asset: PropTypes.object.isRequired,
}

export default GallerySlide
