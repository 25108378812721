import React from 'react'

export const Asscher = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M38 68.4188L75.1785 38H224.822L262 68.4188V231.581L224.822 262H75.1785L38 231.581V68.4188Z"
      stroke="white"
    />
    <rect x="109.588" y="109.588" width="80.8182" height="80.8182" stroke={stroke} />
  </svg>
)

export const Cushion = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M180.682 38H201.055L231.548 48.1643L251.834 68.4505L262 99.7418V201.055L251.836 231.548L231.548 251.836L201.055 262H98.9448L68.4519 251.836L48.1643 231.548L38 201.055V98.9448L48.1643 68.4519L68.4519 48.1643L98.9448 38H180.682Z"
      stroke={stroke}
    />
    <rect
      x="99.5719"
      y="152.325"
      width="74.6049"
      height="74.6049"
      transform="rotate(-45 99.5719 152.325)"
      stroke={stroke}
    />
  </svg>
)

export const Emerald = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M56.75 68.3889L87.1389 38H209.452L239.841 68.3889V231.611L209.452 262H87.1389L56.75 231.611V68.3889Z"
      stroke={stroke}
    />
    <rect x="106.75" y="99.3647" width="80.8182" height="101.273" stroke={stroke} />
  </svg>
)

export const Heart = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M47.0745 134.309L147.606 249.202L257.713 134.309L262.5 96.0106L233.777 62.5H185.904L147.606 96.0106L114.096 62.5H66.2234L37.5 96.0106L47.0745 134.309Z"
      stroke={stroke}
    />
    <path d="M147.604 143.881L85.3701 110.37L147.604 186.966L209.838 110.37L147.604 143.881Z" stroke={stroke} />
  </svg>
)

export const Marquise = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M245.224 197.105L150.987 261.893L56.75 197.105V102.895L150.987 38.1068L245.224 102.895V197.105Z"
      stroke={stroke}
    />
    <path
      d="M191.318 166.481L150.909 195.41L110.5 166.481V133.524L150.909 104.594L191.318 133.524V166.481Z"
      stroke={stroke}
    />
  </svg>
)

export const Oval = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M207.497 62.8317L231.334 116.59L230.702 184.848L205.874 238.156L152.224 261.945L99.0248 237.167L75.1872 183.409L75.9096 105.366L95.7549 61.8048L154.288 38.0493L207.497 62.8317Z"
      stroke={stroke}
    />
    <path
      d="M173.389 114.486L182.254 134.477L182.018 159.938L172.785 179.761L152.904 188.577L133.189 179.395L124.325 159.404L124.594 130.272L131.957 114.11L153.666 105.3L173.389 114.486Z"
      stroke={stroke}
    />
  </svg>
)

export const Pear = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M63.0377 171.643L154.545 38.3833L246.053 171.643L235.902 214.409L210.503 247.697L154.545 261.984L98.5876 247.697L73.1892 214.409L63.0377 171.643Z"
      stroke={stroke}
    />
    <path
      d="M124.404 177.413L154.545 135.515L184.686 177.413L181.359 190.791L173.011 201.235L154.545 205.735L136.079 201.235L127.731 190.791L124.404 177.413Z"
      stroke={stroke}
    />
  </svg>
)

export const Princess = ({ width = 24, height = 24, fill = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.9142 150L150 38.9142L261.086 150L150 261.086L38.9142 150ZM37.5 150L38.2071 149.293L149.293 38.2071L150 37.5L150.707 38.2071L261.793 149.293L262.5 150L261.793 150.707L150.707 261.793L150 262.5L149.293 261.793L38.2071 150.707L37.5 150ZM100.279 150.006L150.001 100.284L199.723 150.006L150.001 199.728L100.279 150.006ZM98.8648 150.006L99.5719 149.299L149.294 99.5768L150.001 98.8697L150.708 99.5768L200.43 149.299L201.137 150.006L200.43 150.713L150.708 200.435L150.001 201.142L149.294 200.435L99.5719 150.713L98.8648 150.006Z"
      fill={fill}
    />
  </svg>
)

export const Radiant = ({ width = 24, height = 24, fill = '#fff', stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M38 68.4188L75.1785 38H224.822L262 68.4188V231.581L224.822 262H75.1785L38 231.581V68.4188Z"
      stroke={fill}
    />
    <rect x="89.3424" y="150" width="85.7813" height="85.7813" transform="rotate(-45 89.3424 150)" stroke={stroke} />
  </svg>
)

export const Round = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M120.253 109.056L150.001 99.3905L179.749 109.056L198.135 134.362V165.641L179.749 190.946L150.001 200.612L120.253 190.946L101.868 165.641V134.362L120.253 109.056Z"
      stroke={stroke}
    />
    <path
      d="M84.1832 59.4109L150 38.0257L215.817 59.4109L256.494 115.398V184.602L215.817 240.589L150 261.974L84.1832 240.589L43.5061 184.602V115.398L84.1832 59.4109Z"
      stroke={stroke}
    />
  </svg>
)

export const Other = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect x="38" y="38" width="224" height="224" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
    <rect
      x="-0.5"
      y="0.5"
      width="11.5"
      height="11.5"
      transform="matrix(-1 0 0 1 105.25 150)"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <rect
      x="-0.5"
      y="0.5"
      width="11.5"
      height="11.5"
      transform="matrix(-1 0 0 1 155.25 150)"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <rect
      x="-0.5"
      y="0.5"
      width="11.5"
      height="11.5"
      transform="matrix(-1 0 0 1 205.25 150)"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const shapes = {
  Asscher,
  Cushion,
  Emerald,
  Heart,
  Marquise,
  Oval,
  Pear,
  Princess,
  Radiant,
  Round,
  Other,
}
